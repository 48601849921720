.support-page {
  font-family: Arial, sans-serif;
  padding: 1rem;

  .support-header {
    background-color: #f8f9fa;
    padding: 1rem;
    text-align: right;

    .privacy-policy-link {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .support-content {
    margin-top: 2rem;
    text-align: center;

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .support-form {
      max-width: 500px;
      margin: 0 auto;
      text-align: left;

      .form-group {
        margin-bottom: 1rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: bold;
        }

        input,
        textarea {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ddd;
          border-radius: 4px;

          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }

      .submit-btn {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
